export const environment = {
    production: false,
    dev       : true,
    useCDN       : false,
    version : '1.1.126',
    projectVersion : '3',
    hostUrl:  'http://localhost:4201',
    firebase: {
        apiKey: 'AIzaSyCi0bQwCeXPZsj-n35Po3fM2OleA441dKs',
        authDomain: 'videobroadcast-83d4e.firebaseapp.com',
        databaseURL: 'https://videobroadcast-83d4e.firebaseio.com',
        projectId: 'videobroadcast-83d4e',
        storageBucket: 'videobroadcast-83d4e.appspot.com',
        messagingSenderId: '148379843937',
        appId: '1:148379843937:web:654690d9283aaeac7d9920',
        measurementId: 'G-HQN7HZ8RD1',
        cdnURL: '',
        statisticsURL: 'https://videobroadcast-stats.europe-west1.firebasedatabase.app'
    },
    recaptcha: {
        secret: '6Leu9T4lAAAAAMY1-jQ_dCGhnSFLlVcPs5CIONh-',
        siteKey: '6Leu9T4lAAAAAFfX7MrBjM4bJCE6lCsKCVo6jrB5'
    },
    settings: {
        lastlogin: 5, // in minutes
    },
    vimeo: {
        token: '2E10AAD2B253E7B8763A31C8FF19AE0014D2F73F370A712E625BDA60270DDC97',
        url: 'https://vimeoapi.prod.easystudio360.tools',
    },
};